import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'

function toLatin (str) {
  const ru = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'j',
    з: 'z',
    и: 'i',
    і: 'i',
    ї: 'e',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'shch',
    ы: 'y',
    э: 'e',
    ю: 'u',
    я: 'ya'
  }
  const n_str = []

  str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i')

  for (let i = 0; i < str.length; ++i) {
    n_str.push(
      ru[str[i]] ||
      ru[str[i].toLowerCase()] === undefined && str[i] ||
      ru[str[i].toLowerCase()].replace(/^(.)/, function (match) {
        return match.toUpperCase()
      })
    )
  }
  return n_str.join('')
    .replace(/ /gi, '-')
    .replace(/([\[\]\/\\])/g, '-')
    .replace(/[ ,.?!]/g, '')
    .toLocaleLowerCase()
}

export default {
  name: 'slug-name-input',
  props: {
    slug: {},
    name: {},
    service: {
      type: String,
      default: 'course'
    },
    disableSlug: {
      default: false
    },
    itemKeyValue: {
      type: String,
      default: 'name'
    },
    isCreated: {
      default: false,
	  type: Boolean
    },
	onlyName: {
      default: false,
      type: Boolean
    },
    entity: {},
    entityId: {},
    countryId: {},
    courseId: {
      default: ''
    },
    moduleId: {
      default: ''
    }
  },
  data () {
    return {
      inputSlugValue: '',
      inputNameValue: '',
      validationErrors: {}
    }
  },
  watch: {
    slug () {
      this.inputSlugValue = this.slug
      this.sendRequest(this.inputSlugValue)
    },
    inputSlugValue () {
      this.sendRequest(this.inputSlugValue)
    },
    name () {
      this.inputNameValue = this.name
    }
  },
  computed: {
    ...mapGetters({
      isCheckSlugLoading: 'helpers/isSlugCheckingLoading'
    })
  },
  created () {
    this.inputSlugValue = this.slug
    this.inputNameValue = this.name
  },
  methods: {
    ...mapActions({
      checkSlug: 'helpers/CHECK_SLUG'
    }),
    sendRequest: debounce(function (e) {
      this.validationErrors = {}
      this.checkSlug({
        service: this.service,
        slug: e,
        entity: this.entity,
        country_id: this.countryId,
        course_id: this.courseId,
        module_id: this.moduleId,
        entity_id: this.entityId
      })
    }, 700),
    changeNameToLatin () {
      this.$emit('update-input', {
        [this.itemKeyValue]: this.inputNameValue,
        slug: toLatin(this.inputNameValue)
      })
    },
    changeInput (e, type = 'name') {
      this.$emit('update-input', {
        [this.itemKeyValue]: this.inputNameValue,
        slug: this.isCreated && type === 'name' ? toLatin(this.inputNameValue) : this.inputSlugValue
      })
    }
  }
}
