import validation from '@/mixins/validation'
import VueGallery from 'vue-gallery'
import seoItem from './seo-item/index.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import Vue from 'vue'

export default {
  name: 'image-file',
  mixin: [validation],
  props: {
    value: {},
    typeId: {
      type: Number,
      default: 1
    },
    entity: {
      type: String,
      default: ''
    },
    entityId: {},
    service: {
      type: String,
      default: 'course'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'image'
    },
    isHasSeo: {
      type: Boolean,
      default: true
    },
    imageSize: {
      type: String,
      default: '1050x600'
    },
    imageLabel: {
      type: String,
      default: 'list image'
    }
  },
  data () {
    return {
      fileSrc: '',
      fileName: '',
      index: null,
      isShowDialog: false,
      valid: false,
      isEmptyFileField: true,
      currentLocale: '',
      payload: {
        src: '',
        typeId: this.typeId,
        entityId: this.entityId,
        entity: this.entity,
        service: this.service
      },
      seo: this.value && this.value.hasOwnProperty('seo') ? this.value.seo.data : []
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.fileSrc = this.value?.src
        if (this.typeId === 2) this.fileName = this.value?.file_name
      }
    },
    loading () {
      // close popup after update image
      if (!this.loading && this.typeId === 1) {
        this.isShowDialog = false
      }
    }
  },
  components: {
    gallery: VueGallery,
    seoItem
  },
  computed: {
    ...mapGetters({
      locales: 'profile/locales',
      list: 'files/list',
      isLoadingList: 'files/isLoadingList',
      isLoadingUpload: 'files/isLoadingUpload'
    }),

    hasTypeFile () {
		  return this.typeId === 2
    }

  },
  created () {
    this.fetchFiles(this.payload)
    if (this.value) {
      this.fileSrc = this.value?.src
      if (this.typeId === 2) this.fileName = this.value?.file_name
    }
  },
  methods: {
    ...mapActions({
      upload: 'files/UPLOAD',
      fetchFiles: 'files/GET_LAST_UPLOAD_FILES'
    }),
    ...mapMutations({
      setList: 'files/SET_LAST_UPLOAD_FILES'
    }),
    changeImage (file) {
      if (file) {
        if (this.typeId === 2) this.fileName = file.name
        this.fileSrc = URL.createObjectURL(file)
        this.payload.src = file
        this.isEmptyFileField = false
      } else {
        this.clearImg()
      }
    },
    changeDialog (status) {
      if (!status) {
        this.fileSrc = this.value?.src
        if (this.typeId === 2) this.fileName = this.value?.file_name
      }
      this.isShowDialog = status
    },
    openFullImage () {
      if (this.fileSrc) {
        this.index = 1
      }
    },
    clearImg () {
      this.fileSrc = this.value?.src
      this.isEmptyFileField = true
    },
    updateImgFields (params) {
      const index = this.seo.findIndex(e => e.locale === params.locale)
      if (index >= 0) {
        this.seo[index] = params
        Vue.set(this.seo, index, params)
      } else {
        this.seo.push(params)
      }
    },
    submit () {
      const formData = new FormData()
      formData.append('src', this.fileSrc)
      this.type !== 'image' && formData.append('type_id', this.type === 'cover' ? 1 : 2)

      if (this.value && this.value.hasOwnProperty('id')) {
        formData.append('id', this.value?.id)
        formData.append('_method', 'patch')
      }

      if (this.typeId === 1 && this.seo.length) {
        this.seo.forEach((e, i) => {
          formData.append(`seo[${i}][locale]`, e.locale)
          formData.append(`seo[${i}][title]`, e.title)
          formData.append(`seo[${i}][alter_text]`, e.alter_text)
        })
      }

      this.$emit('update', formData)
      if (this.typeId === 2) {
        this.isShowDialog = false
      }
    },

    createRequestPayload () {
      const formData = new FormData()
      for (const i in this.payload) {
        formData.append(snakeCase(i), this.payload[i])
      }
      return formData
    },
    uploadFile () {
      this.upload(this.createRequestPayload()).then(response => {
        this.fileSrc = response.src
        this.$toasted.success(this.$t('success_upload'))
      })
    }
  },
  destroyed () {
    this.setList([])
  }
}
