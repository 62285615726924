import { mapActions, mapGetters, mapMutations } from 'vuex'
import validation from '@/mixins/validation'
import ImageFile from '@/components/image-file/index.vue'
import { positionTypes } from '../../helpers'
import { camelCase, debounce } from 'lodash'

export default {
  name: 'users-position-general',
  mixins: [validation],
  props: ['moduleData'],
  data () {
    return {
      valid: false,
      validationErrors: {},
      search: '',
      searchInput: '',
      payload: {
        avatar: '',
        active: this.moduleData ? true : false,
        positionId: this.moduleData ? this.moduleData.type : '',
        fullName: '',
        countryId: this.moduleData? this.moduleData.country_id : '',
        userId: '',
        activity: ''
      }
    }
  },
  components: {
    ImageFile
  },
  computed: {
    ...mapGetters({
      users: 'user/users',
      usersLoading: 'user/usersLoading',
      profile: 'profile/profile',
      currentItem: 'userPositions/currentItem',
      isLoading: 'userPositions/isLoading',
      updateImageLoading: 'userPositions/updateImageLoading',
      openDialogView: 'userPositions/openDialogView',
      accessCountries: 'profile/accessCountries'
    }),
    avatarObj () {
      if (!this.currentStudent) return null;

      return {
        id: this.currentItem ? this.currentItem.id : null,
        src: this.payload.avatar
      }
    },
    positionTypes
  },
  created () {
    this.setData()
    if (!this.currentItem) {
      this.fetchUsers({
        keywords: ''
      })
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: 'user/GET_USERS',
      create: 'userPositions/CREATE',
      update: 'userPositions/UPDATE',
      updateImage: 'userPositions/UPDATE_IMAGE'
    }),
    ...mapMutations({
      changeDialogView: 'userPositions/CHANGE_DIALOG_VIEW'
    }),
    updateImageLocal (params) {
      this.payload.avatar = params.get('src')
      this.submit()
    },
    setData () {
      const transformObj = {}
      const item = this.currentItem
      for (const i in item) {
        transformObj[camelCase(i)] = item[i]
      }
      if (!this.moduleData) {
        Object.assign(this.payload, transformObj, {
          countryId: transformObj.country.data.id,
          positionId: transformObj.position === 'author' ? 1 : 2
        })
      }
    },
    changeInputUsers: debounce(function (val) {
      if (!val) return
      if (this.search.fullName !== val) {
        this.fetchUsers({
          keywords: val,
          countries:  this.moduleData ? this.moduleData.country_id : null
        })
      }
    }, 500),
    autoCompleteChangedUser (e) {
      if (!e) {
        Object.keys(this.payload).forEach(t => {
          if (t !== 'avatar') {
            this.payload[t] = ''
          }
        })
        this.search = ''
      } else {
        this.search = e
        Object.keys(this.payload).forEach(t => {
          if (t !== 'avatar') {
            this.payload[t] = e[t]
          }
        })
        this.payload.positionId = this.positionTypes[0].value
        this.payload.active = true
      }
    },
    createRequestPayload () {
      const data = new FormData()
      data.append('full_name', this.payload.fullName)
      data.append('country_id', this.payload.countryId)
      data.append('active', this.payload.active ? '1' : '0')
      data.append('avatar', this.payload.avatar)
      data.append('position_id', this.moduleData ? this.moduleData.type : this.payload.positionId)
      data.append('activity', this.payload.activity)

      if (this.currentItem && !this.moduleData) {
        data.append('id', +this.currentItem.id)
        data.append('_method', 'patch')
      } else {
        data.append('user_id', +this.payload.userId || '')
      }
      return data
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem && !this.moduleData) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeDialogView(false)
            this.$emit('close')
          })
        } else {
          this.create(this.createRequestPayload()).then((data) => {
            this.$toasted.success(this.$t('success_created'))
			      this.$emit('close', data)
          })
        }
      }
    }
  }
}
