import { mapGetters } from 'vuex'

export default {
  name: 'seo-form-todo',
  props: {
    tags: {},
    item: {},
    index: {},
  },
  data(){
    return {
      payload: {
        value: '',
        key: ''
      }
    }
  },
  watch: {
    payload: {
      handler(){
        this.update()
      },
      deep: true
    },
    item(){
      Object.assign(this.payload, this.item)
    }
  },
  computed:{
  },
  created(){
    Object.assign(this.payload, this.item)
  },
  methods: {
    update(){
      this.$emit('update-item', {...this.payload, index: this.index})
    },
    removeTag(){
      this.$emit('delete-item', this.index)
    }
  }
}
