import { mapActions, mapGetters, mapMutations } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validationMixin from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'

import SelectPackages from '@/components/forms/SelectPackages';
import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'course-contents-materials-general',
  mixin: [mixins, validationMixin],
  data () {
    return {
      valid: false,
      openDatepicker: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        description: '',
        image: '',
        picture: '',
        statusId: '',
        packages: [],
        sort: 1,
        dateAt: '',
        speakers: [],
      }
    }
  },
  components: {
    SlugNameInput,
    MainEditor,
    ImageFile,
	SelectPackages,
    SelectUserPosition
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      packagesList: 'coursesPackages/list',
      currentCourse: 'coursesInfo/info',
      setting: 'courses/setting',
      editId: 'contentMaterials/editId',
      currentItem: 'contentMaterials/currentItem',
      isItemLoading: 'contentMaterials/isLoading',
      updateImageLoading: 'contentMaterials/updateImageLoading',
      speakers: 'user/speakers',
      speakersLoading: 'user/speakersLoading'
    }),
    getMultipleEntityWithRouteName () {
      switch (this.$route.name) {
        case 'courses.contents.bonuses': {
          return 'bonuses'
        }
        case 'courses.contents.meetups': {
          return 'meetups'
        }
        case 'courses.contents.qa-sessions': {
          return 'qa-sessions'
        }
      }
    },
    getEntityWithRouteName () {
      switch (this.$route.name) {
        case 'courses.contents.bonuses': {
          return 'bonus'
        }
        case 'courses.contents.meetups': {
          return 'meetup'
        }
        case 'courses.contents.qa-sessions': {
          return 'qa_session'
        }
      }
    }
  },
  created () {
    if (this.editId) {
      if (!this.currentItem) {
        this.fetchData({
          course_id: this.$route.params.course_id,
          id: this.editId
        }).then(() => {
          this.setData()
          this.fetchSpeakers({
            isAuthors: this.$route.name === 'courses.contents.bonuses',
            id: this.payload.speakers.join(',')
          }).then(() => {
            this.setData()
          })
        })
      } else {
        this.setData()
        this.fetchSpeakers({
          isAuthors: this.$route.name === 'courses.contents.bonuses',
        }).then(() => {
          this.setData()
        })
      }
    } else {
      this.setData()
      this.fetchSpeakers({
        isAuthors: this.$route.name === 'courses.contents.bonuses',
        keywords: '',
      }).then(() => {
        this.setData()
      })
    }

	this.$on('event-save', () =>{
	  this.submit();
	})
  },
  methods: {
    ...mapActions({
      fetchData: 'contentMaterials/GET_ITEM',
      create: 'contentMaterials/CREATE',
      update: 'contentMaterials/UPDATE',
      updateImage: 'contentMaterials/UPDATE_IMAGE',
      fetchSpeakers: 'user/GET_SPEAKERS'
    }),
    ...mapMutations({
      changeId: 'contentMaterials/CHANGE_EDIT_ID'
    }),

    onReloadUserPosition(){
        this.fetchSpeakers({
            isAuthors: this.$route.name === 'courses.contents.bonuses',
        })
    },

    updateImageLocal (params) {
      params.append('course_id', this.$route.params.course_id)
      params.append('material_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    setData () {
      const transformObj = {}
      const item = this.currentItem
      if (item) {
        Object.keys(item).forEach(e => {
          transformObj[camelCase(e)] = item[e]
        })
        Object.assign(this.payload, transformObj)
        this.payload.packages = item?.packages?.data.map(e => e.id)
        this.payload.speakers = item?.speakers?.data.map(e => e.user_position_id)
        this.search = this.payload.speakers
      }
    },
    // speakers
    changeInputSpeakers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchSpeakers({
          isAuthors: this.$route.name === 'courses.contents.bonuses',
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.speakers = e.map(e => e.id)
      this.search = e
    },
    // speakers end
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = new FormData()
      Object.keys(data).forEach(e => {
        if (e !== 'typeId') {
          formData.append(snakeCase(e), data[e])
        }
      })
      formData.append('course_id', this.$route.params.course_id)
      formData.append('date_at', moment(this.payload.dateAt).format('YYYY-MM-DD HH:mm:ss'))
      if (this.$route.name === 'courses.contents.bonuses' && this.payload.speakers && this.payload.speakers.length) {
        formData.append('authors', this.payload.speakers?.join(','))
      }
      if (this.currentItem) {
        formData.append('_method', 'patch')
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()

      if (this.valid) {

        if (!this.currentItem) {
          this.create(this.createRequestPayload()).then(response => {
            this.fetchData(response).then(() => {
              this.setData()
            })
            this.changeId(response.id)
            this.$toasted.success(this.$t('success_created'));
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'));
		  })
        }
      }
    }
  }
}
