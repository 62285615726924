import item from './item/index.vue'
import { clone, differenceBy, toArray } from 'lodash'
import Vue from 'vue'

export default {
  name: 'seo-form-todo',
  props: {
    label: {
      type: String,
      default: ''
    },
    tags: {},
    selected: {},
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: []
    }
  },
  watch: {
    selected () {
      this.list = this.selected
    },
  },
  components: {
    item
  },
  computed: {
    availableTags () {
      return differenceBy(this.tags, this.transformTags, 'key')
    },
    transformTags () {
      const list = clone(this.tags)
      return list.map(e => {
        const obj = {
          key: e,
          value: ''
        }
        return obj
      })
    }
  },
  created () {
    if(!this.selected) return
    this.list = this.selected || []
  },
  methods: {
    addTag () {
      this.list.push({
        key: '',
        value: ''
      })
      this.updateList()
    },
    updateList(){
      this.$emit('update', { list: this.list, type: this.type })
    },
    updateItem(item){
      const {index, ...others} = item
      Vue.set(this.list, index, others)
      this.updateList()
    },
    deleteItem(index){
      Vue.delete(this.list, index)
      this.updateList()
    }
  }
}
