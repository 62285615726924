export function courseContentMaterialsTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id'
    },
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Date at',
      value: 'startAt'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function courseContentMaterialsContentTableHead () {
  return [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Type',
      value: 'type'
    },
    {
      text: 'Packages',
      value: 'packages'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
