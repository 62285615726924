import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validation from '@/mixins/validation'

import Draggable from 'vuedraggable'
import MaterialsViewDialog from '../dialog-view/index.vue'

import { courseContentMaterialsTableHead } from '@/modules/courses/helpers/content-materials'

export default {
  name: 'courses-contents-materials-main',
  mixin: [course, mixins, validation],
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      showActionsDialog: false,
      valid: false,
      removingItem: null,
      openDialogView: false,
      materials: []
    }
  },
  components: {
    MaterialsViewDialog,
    Draggable
  },
  computed: {
    ...mapGetters({
      filterKeywords: 'contentMaterials/filterKeywords',
      list: 'contentMaterials/list',
      isListLoading: 'contentMaterials/isListLoading',
      isLoading: 'contentMaterials/isLoading',
      listLength: 'contentMaterials/listLength',
      limit: 'contentMaterials/limit',
      skip: 'contentMaterials/skip',
      packagesList: 'coursesPackages/list'
    }),
    courseContentMaterialsTableHead
  },
  watch: {
    'list' () {
      this.materials = this.list
    }
  },
  created () {
    this.changeMaterialType(this.$route.name.split('.').pop().split('').join(''))
    this.resetMain()
    if (!this.list.length) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.materials = this.list
      })
    } else {
      this.materials = this.list
      if (this.skip) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'contentMaterials/GET_LIST',
      remove: 'contentMaterials/DELETE',
      updateSort: 'contentMaterials/UPDATE_SORT'
    }),
    ...mapMutations({
      changeFilter: 'contentMaterials/CHANGE_FILTER',
      changeSkip: 'contentMaterials/CHANGE_SKIP',
      changeId: 'contentMaterials/CHANGE_EDIT_ID',
      resetMain: 'contentMaterials/RESET',
      changeMaterialType: 'contentMaterials/CHANGE_MATERIAL_TYPE'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.sendRequest()
    },
    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    openDialogDelete (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    removeListItem () {
      this.remove({
        course_id: this.$route.params.course_id,
        id: this.removingItem.id
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogView (item = null) {
      this.changeId(item ? item.id : '')
      this.openDialogView = true
    },
    closeDialogView () {
      setTimeout(() => {
        this.openDialogView = false
      }, 500)
    },
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const formData = {
          course_id: this.$route.params.course_id,
          qa_sessions: this.materials.map(e => e.id)
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000),
    sendRequest: debounce(function () {
      this.fetchList({ course_id: this.$route.params.course_id })
    }, 1000)
  },
  destroyed () {
    this.resetMain()
  }
}
