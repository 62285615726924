<template>
	<div class="select">
		<v-select :value="value"
				@input="$emit('input', $event)"
				:items="items"
				:disabled="!items.length"
				item-text="name"
				item-value="id"
				chips
				small-chips
				label="Packages"
				multiple
		>
			<template v-slot:prepend-item>
				<v-list-item
						ripple
						@click="onToggleAllPackages"
				>
					<v-list-item-action>
						<v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
							{{ icon }}
						</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>
							Select All
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mt-2"></v-divider>
			</template>
		</v-select>
	</div>
</template>

<script>
  export default {
	name: 'select-packages',
    props: {
	  value: {
		validator: function (value) {
		  return Array.isArray(value) ? value : []
		}
	  },
	  items: Array,
	  rules: Array
	},

    computed:{
	  packagesIds () {
		return this.items.map(el => {
		  return el.id
		})
	  },

	  selectedAllPackage () {
		return this.value && this.value.length === this.packagesIds.length
	  },

	  selectedSomePackage () {
		return this.value && this.value.length > 0 && !this.selectedAllPackage
	  },

	  icon () {
		if (this.selectedAllPackage) return 'mdi-close-box'
		if (this.selectedSomePackage) return 'mdi-minus-box'
		return 'mdi-checkbox-blank-outline'
	  }
    },

    methods:{
	  onToggleAllPackages () {
		this.$nextTick(() => {
		  if (this.selectedAllPackage) {
		    this.$emit('input', []);

		  } else {
			this.$emit('input', this.packagesIds.slice())
		  }
		})
	  },
    }

  };
</script>

<style scoped lang=scss>

</style>
