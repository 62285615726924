import { mapActions, mapGetters } from 'vuex'
import { camelCase, debounce, snakeCase } from 'lodash'

import validationMixin from '@/mixins/validation'
import mixins from '@/mixins'

import Chapters from '@/components/video-chapters/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import Draggable from 'vuedraggable'

import { courseContentMaterialsContentTableHead } from '@/modules/courses/helpers/content-materials'

import SelectPackages from '@/components/forms/SelectPackages';

export default {
  name: 'courses-contents-materials-content',
  mixin: [mixins, validationMixin],
  data () {
    return {
      showContentDialog: false,
      showRemoveDialog: false,
      removingItem: null,
      selectedItem: null,
      valid: false,
      contents: [],
      payload: {
        typeId: '',
        name: '',
        statusId: '',
        type: '',
        sort: '',
        packages: '',
        link: '',
        src: ''
      }
    }
  },
  components: {
    ImageFile,
    Draggable,
    Chapters,
	SelectPackages
  },
  watch: {
    'list' () {
      this.contents = this.list
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      packagesList: 'coursesPackages/list',
      editId: 'contentMaterials/editId',
      currentItem: 'contentMaterials/currentItem',
      loading: 'contentMaterialsContent/loading',
      list: 'contentMaterialsContent/list',
      listLoading: 'contentMaterialsContent/listLoading',
      isLoadingChapter: 'contentChapters/isLoading'
    }),
    courseContentMaterialsContentTableHead,
  },
  created () {
    if (this.list.length <= 0) {
      this.fetchList({
        course_id: this.$route.params.course_id,
        material_id: this.currentItem.id,
        id: this.editId
      }).then(() => {
        this.contents = this.list
      })
    } else {
      this.contents = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'contentMaterialsContent/GET_LIST',
      create: 'contentMaterialsContent/CREATE',
      remove: 'contentMaterialsContent/DELETE',
      update: 'contentMaterialsContent/UPDATE',
      updateSort: 'contentMaterialsContent/UPDATE_SORT',

      createChapter: 'contentChapters/CREATE',
      removeChapter: 'contentChapters/DELETE',
      updateChapter: 'contentChapters/UPDATE'
    }),
    updateImageLocal (params) {
      this.payload.src = params.get('src')
    },

    // remove
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = {
        course_id: this.$route.params.course_id,
        material_id: this.currentItem.id,
        id: item.id
      }
    },
    // actions data
    setData (item) {
      const transformObj = {}
      Object.keys(item).forEach(e => {
        transformObj[camelCase(e)] = item[e]
      })
      Object.assign(this.payload, transformObj)
      this.payload.packages = item.packages.data.map(e => e.id)
    },

    // popup change content
    resetPayload () {
      this.selectedItem = null
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.payload.sort = 1
      this.$refs.form.reset()
    },
    changeShowDialogContent (item = null, status = false, type = 'file') {
      this.payload.type = type
      if (item) {
        this.setData(item)
        this.payload.type = type
        this.selectedItem = item
      }
      this.showContentDialog = status
      if (!status) {
        this.resetPayload()
      }
    },
    createRequestPayload () {
      const formData = new FormData()

      Object.keys(this.payload).forEach(e => {
        if (e !== 'typeId') {
          formData.append(snakeCase(e), this.payload[e])
        }
      })

      formData.append('course_id', this.$route.params.course_id)
      formData.append('material_id', this.currentItem.id)
      if (this.selectedItem) {
        formData.append('_method', 'patch')
      }
      // if added new type you need take setting.content.types
      formData.append('type_id', this.payload.type === 'video' ? 1 : 2)
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (!this.selectedItem) {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
            this.changeShowDialogContent()
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeShowDialogContent()
          })
        }
      }
    },
    submitChapter (elem) {
      elem.contentId = this.selectedItem.id
      if (elem.hasOwnProperty('id')) {
        this.updateChapter(elem).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      } else {
        this.createChapter(elem).then(() => {
          this.$toasted.success(this.$t('success_created'))
        })
      }
    },
    removeChapterLocal (elem) {
      this.removeChapter(elem).then(() => {
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const sortsList = this.contents.map(e => e.id)
        const formData = {
          course_id: this.$route.params.course_id,
          material_id: this.currentItem.id,
          contents: sortsList
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
