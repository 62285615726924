<template>
  <div class="posit">
    <div class="posit__main">
      <slot></slot>
    </div>
    <div class="posit__sub">
      <!--<div class="btn" @click="onAddUserPosition">add!</div>-->
      <div
        class="mr-1 v-btn v-btn--contained theme--dark v-size--default success"
        @click="onAddUserPosition"
      >
        Add
      </div>
    </div>

    <v-dialog :retain-focus="false" v-model="showAddDialog" max-width="950">
      <v-card tile>
        <v-toolbar
          style="flex: 0 1 auto"
          height="60px"
          flat
          dark
          color="primary"
        >
          <v-btn icon dark @click="showAddDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> User position: Create </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="showAddDialog = false" color="primary" class="mr-1">
            {{ $t("cancel") }}
          </v-btn>
        </v-toolbar>
        <general
          :moduleData="moduleData"
          ref="general"
          @close="onClose"
        ></general>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import general from '@/modules/users/positions/pages/general/index.vue';

export default {
  name: 'index',
  components: {
    general
  },
  props: ['type', 'country_id'],
  data () {
    return {
      showAddDialog: false,

      moduleData: {
        type: this.type,
        country_id: this.country_id
      }
    }
  },
  methods: {
    onAddUserPosition () {
      this.showAddDialog = true
    },

    onClose (data) {
      this.showAddDialog = false
      this.$emit('reload-user-position', data)
    }
  }
}
</script>

<style scoped lang='scss'>
.posit {
  display: flex;
  align-items: center;
  &__main {
    width: 100%;
    padding-right: 10px;
  }
}
</style>
