import { mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'

import General from '../general/index.vue'
import ContentMaterial from '../content/index.vue'
import Seo from '../seo/index.vue'

export default {
  name: 'course-contents-materials-dialog-view',
  data () {
    return {
      dialog: true,
      activeTab: 'general',
    }
  },
  components: {
    General,
    ContentMaterial,
    Seo
  },
  computed: {
    ...mapGetters({
      currentItem: 'contentMaterials/currentItem',
      editId: 'contentMaterials/editId',
      selectedMaterialType: 'contentMaterials/selectedMaterialType',
	  isItemLoading: 'contentMaterials/isLoading',
	  updateSeoLoading: 'contentMaterials/updateSeoLoading',
    }),
    tabs () {
      return [
        {
          name: 'General',
          slug: 'general',
          id: 1
        },
        {
          name: 'Content',
          slug: 'content',
          id: 2,
          disabled: !this.currentItem
        },
        {
          name: 'SEO',
          slug: 'seo',
          id: 3,
          disabled: !this.currentItem
        }
      ]
    },
    formattedKeyMaterial () {
      return snakeCase(this.selectedMaterialType)
    }
  },
  methods: {
    ...mapMutations({
      resetGeneral: 'contentMaterials/SET_ITEM',
      resetContent: 'contentMaterialsContent/SET_LIST',
      resetSeo: 'contentMaterials/SET_SEO'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.$emit('close-dialog')
      this.resetSeo(null)
      this.resetGeneral(null)
      this.resetContent([])
    },

	save(){
	  this.$refs['general'].$emit('event-save');
	}
  },
}
