export const filterProps = {
  positionsTypes () {
    return [
      {
        name: 'Only authors',
        id: 1
      },
      {
        name: 'Only speakers',
        id: 2
      }
    ]
  },
  statuses () {
    return [
      {
        name: 'All',
        value: ''
      },
      {
        name: 'Active',
        value: '1'
      },
      {
        name: 'Inactive',
        value: '0'
      }
    ]
  }
}

export function positionTableHead () {
  return [
    {
      text: 'Name',
      value: 'fullName'
    },
    {
      text: 'Type',
      value: 'position'
    },
    {
        text: 'Activity',
        value: 'activity'
    },
    {
      text: 'Country',
      value: 'country',
      sortable: false
    },
    {
      text: 'Active',
      value: 'active',
      sortable: false
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'actions'
    }
  ]
}

export function positionTypes () {
  return [
    {
      name: 'Author',
      value: 1
    },
    {
      name: 'Speaker',
      value: 2
    }
  ]
}
