import Vue from 'vue'
import todo from '../seo-form-todo/index.vue'
import { camelCase, size, snakeCase } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'seo-form',
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      default: ''
    },
    contentLoading: {
      default: false,
      type: Boolean
    },
    entryLocales: {},
    locale: {
      type: String,
      default: ''
    },
    updateLoading: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      valid: false,
      currentLocale: '',
      currentItem: null,
      locales: [],
      payload: {
        locale: this.locale,
        title: '',
        h1: '',
        description: '',
        robots: '',
        canonical: '',
        openGraphTags: [],
        twitterCardTags: []
      }
    }
  },
  components: {
    todo
  },
  watch: {
    content: {
      handler () {
        if (this.content) {
          this.setContent()
        }
      },
      deep: true
    }
  },
  computed: {
    listOpenGraphTags () {
      return this.setting?.seo?.open_graph_tags.map(e => {
        return e
      })
    },
    listTwitterCardTags () {
      return this.setting?.seo?.twitter_card_tags.map(e => {
        return e
      })
    },
    ...mapGetters({
      setting: 'courses/setting',
      profileLocales: 'profile/locales'
    })
  },
  created () {
    if (this.entryLocales && this.entryLocales.length) {
      this.locales = this.entryLocales.map(e => e.locale)
    } else {
      this.locales = this.profileLocales
    }
    if (this.content.length > 0) {
      if (this.locale) {
        this.changeTab(this.locale)
      } else {
        this.changeTab(this.content[0].locale)
      }
    }

    this.setTitleWhenContentEmpty();

    this.$on('event-submit', () =>{
      this.submit();
    })
  },
  methods: {
    setTitleWhenContentEmpty () {
      if (!this.currentLocale) {
        this.currentLocale = this.locales[0]
      }
      if (!!this.content && !this.content.find(e => e.locale === this.currentLocale)) {
        this.payload.title = this.title
      }
    },
    updateTags (params) {
      const { type, list } = params
      Vue.set(this.payload, camelCase(type), list)
    },
    setContent () {
      this.currentItem = this.content.find(e => e.locale === this.currentLocale)
      Object.assign(this.payload, this.currentItem)
      this.payload.locale = this.currentLocale
    },
    createRequestPayload () {
      const formData = {}
      formData.open_graph_tags = {}
      formData.twitter_card_tags = {}
      const data = this.payload
      for (const i in data) {
        if (i === 'openGraphTags' || i === 'twitterCardTags') {
          data[i].forEach(e => {
            formData[snakeCase(i)][e.key] = e.value
          })
        } else {
          formData[snakeCase(i)] = data[i]
        }
      }
      delete formData.h_1
      // ...
      if (!size(formData.open_graph_tags)) {
        formData.open_graph_tags = null
      }
      if (!size(formData.twitter_card_tags)) {
        formData.twitter_card_tags = null
      }

      formData.h1 = this.payload.h1
      if (this.currentItem) {
        formData._method = 'patch'
        formData.id = this.currentItem.id
      }
      return formData
    },
    changeTab (locale) {
      this.currentLocale = locale
      this.currentItem = this.content.find(e => e.locale === locale)
      if (!this.currentItem) {
        this.payload = {
          locale: locale,
          title: '',
          h1: '',
          description: '',
          robots: '',
          canonical: '',
          openGraphTags: [],
          twitterCardTags: []
        }
        if (this.$refs.form) {
          this.$refs.form.reset()
        }
        this.setTitleWhenContentEmpty()
        return
      }
      Object.assign(this.payload, this.currentItem)

      this.payload.locale = locale
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.$emit('on-submit', this.createRequestPayload())
      }
    }
  }
}
