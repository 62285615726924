export default {
  name: 'video-chapters-item',
  props: {
    item: {
      type: Object,
      default: {}
    },
    index: {
      type: Number
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      payload: {
        timeFrom: '',
        description: ''
      }
    }
  },
  created () {
    Object.assign(this.payload, this.item)
  },
  methods: {
    handleInput () {
      this.$emit('update-data', {
        ...this.payload,
        index: this.index
      })
    },
    submit () {
      this.$emit('submit', this.payload)
    },
    deleteItem () {
      this.$emit('delete-item', this.payload)
    }
  }
}
