export default {
  name: 'item-image-seo',
  props: {
    seo: {

    },
    locale: {
      type:String,
    }
  },
  data(){
    return {
      payload: {
        alter_text: '',
        title: '',
        locale: this.locale
      }
    }
  },
  computed: {
    currentItem(){
      return this.seo.find(e => e.locale === this.locale)
    }
  },
  created(){
    if(this.seo.length){
      this.setData();
    }
  },
  methods: {
    updateField(){
      this.$emit('update-item', this.payload)
    },
    setData(){
      if(!this.currentItem) return
      this.payload.title = this.currentItem.title
      this.payload.alter_text = this.currentItem.alter_text
    }
  }
}
