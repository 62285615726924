import Vue from 'vue'
import item from './video-chapter-item/index.vue'
import { camelCase, snakeCase } from 'lodash'

export default {
  name: 'video-chapters',
  props: {
    chapters: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  watch: {
    chapters: {
      handler () {
        this.setData()
        this.updateReactivityData()
      },
      deep: true
    }
  },
  data () {
    return {
      list: [],
      isUpdateRender: true
    }
  },
  components: {
    item
  },
  created () {
    this.setData()
  },
  methods: {
    updateData (params) {
      const { index, ...data } = params
      Vue.set(this.list, index, data)
    },
    addItem () {
      this.list.push({
        timeFrom: '',
        description: ''
      })
    },
    submit (item) {
      const formData = {}
      for (const i in item) {
        formData[snakeCase(i)] = item[i]
      }
      if (item.hasOwnProperty('id')) {
        formData._method = 'patch'
      }

      this.$emit('submit', formData)
    },
    deleteItem (item) {
      this.$emit('remove', item)
    },
    setData () {
      this.list = this.chapters.map(e => {
        const obj = {}
        for (const i in e) {
          obj[camelCase(i)] = e[i]
        }
        return obj
      })
    },
    updateReactivityData () {
      this.isUpdateRender = false
      this.$nextTick(() => {
        this.isUpdateRender = true
      })
    }
  }
}
