import { mapActions, mapGetters } from 'vuex'
import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'course-contents-materials-seo',
  computed: {
    ...mapGetters({
      seo: 'contentMaterials/seo',
      seoLoading: 'contentMaterials/seoLoading',
      updateSeoLoading: 'contentMaterials/updateSeoLoading',
      currentItem: 'contentMaterials/currentItem'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      this.fetchData({
        course_id: this.$route.params.course_id,
        id: this.currentItem.id
      })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },
  methods: {
    ...mapActions({
      fetchData: 'contentMaterials/GET_SEO',
      update: 'contentMaterials/UPDATE_SEO'
    }),
    submit (formData) {
      formData.course_id = this.$route.params.course_id
      formData.material_id = this.currentItem.id

      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
	  })
    }
  }
}
